import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"
import Contact from "../components/contact/Contact"
import Form from "../components/contact/Form"
import Details from "../components/contact/Details"

const ContactPage = () => {

  const data = useStaticQuery(graphql`
    query {
      officeImage: file(relativePath: { eq: "office-vestergade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InnerLayout>
      <SEO title="Contact"/>
      <InnerWrapper>
        <section className="Contact outer-padding-x outer-padding-y">
          <div className="Contact__top">
            <div className="Contact__inner max-width-big">
              <Contact/>
              <Form/>
            </div>
            <div className="Contact__image fadein">
              <Img fluid={data.officeImage.childImageSharp.fluid} />
            </div>
          </div>
        </section>
        <Details/>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default ContactPage
