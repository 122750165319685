import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';

import Loader from '../../images/loader';

class Form extends Component {

  constructor(props) {
    super(props)
    this.submitBtn = React.createRef();
  }

  state = {
    status: 'IDLE',
    name: '',
    email: '', 
    message: ''
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    // Prevent default
    event.preventDefault();
    // Blur submit btn
    this.submitBtn.current.blur();
    // Set form state to sending
    this.setState({ status: 'SENDING' });
    // Data to send
    const { name, email, message } = this.state;
    // Send form
    axios.post('/api/contact', {name, email, message}, {})
    .then((response) => {
      // Success
      // console.log(response.data);
      setTimeout( () => {
        this.setState({ status: 'SUCCESS' });
      }, 500);
    })
    .catch((error) => {
      // Form submit encountered an error
      setTimeout( () => {
        this.setState({ status: 'ERROR' });
      }, 500);
    })

  };

  render() {
    const { status, name, email, message } = this.state;
    const formClass = classNames('Form Contact__form max-width-big', {
      'Form--sending': status === 'SENDING',
      'Form--success': status === 'SUCCESS',
      'Form--error': status === 'ERROR',
    });
    const nameInputClass = classNames('Form__input', { 'Form__input--hasContent': name });
    const emailInputClass = classNames('Form__input', { 'Form__input--hasContent': email });
    const textareaInputClass = classNames('Form__input', { 'Form__input--hasContent': message });
    return (
      <div className="Contact__form fadein">
        <h2>Contact form</h2>
        <form className={formClass} onSubmit={this.handleSubmit}>
          <div className={nameInputClass}>
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={this.handleChange}
              required
            />
            <label htmlFor="name">First name</label>
            <div className="Form__inputbar"></div>
          </div>
          <div className={emailInputClass}>
            <input
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={this.handleChange}
              required
            />
            <label htmlFor="email">Email address</label>
            <div className="Form__inputbar"></div>
          </div>
          <div className={textareaInputClass}>
            <textarea 
              id="message" 
              name="message" 
              onChange={this.handleChange} 
              value={message}
              required
            ></textarea>
            <label htmlFor="message">Your message</label>
            <div className="Form__inputbar"></div>
          </div>
          <div className="Form__input">
            { status !== 'SUCCESS' ? <input type="submit" value={status === 'SENDING' ? 'Sending...' : 'Send message'} ref={this.submitBtn}/> : null }
            <div className="Form__indicator">
              <Loader />
            </div>
          </div>
          <div className="Form__response">
            {status === 'ERROR' ? <p>An error ocurred. Try again. <span role="img" aria-label="Broken heart">💔</span></p> : null}
            {status === 'SUCCESS' ? <p>Thank you for your message! I will get back to you ASAP. <span role="img" aria-label="Raised hands">🙌</span></p> : null}
          </div>
        </form>
      </div>
    )
  }

}

export default Form