import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Details = () => {

  const data = useStaticQuery(graphql`
    query {
      mapImage: file(relativePath: { eq: "hhb-map.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mapImageDark: file(relativePath: { eq: "hhb-map-dark.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="outer-padding-x outer-padding-y">
      <div className="Contact__details bg-lightgray fadein">
        <div>
          <p>hhb - udvikling & design</p>
          <a href="https://www.google.com/maps/place/Vestergade+18C,+1456+K%C3%B8benhavn/@55.677693,12.5671603,17z/" target="_blank" rel="noopener noreferrer">
            <address>
              Vestergade 18C<br></br>
              1456 Copenhagen K<br></br>
              Denmark
            </address>
          </a>
          <p><a href="mailto:henrik@hhb.dk">henrik@hhb.dk</a></p>
          <p>CVR-nr. DK38439561</p>
        </div>
        <div>
          <a href="https://www.google.com/maps/place/Vestergade+18C,+1456+K%C3%B8benhavn/@55.677693,12.5671603,17z/" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.mapImage.childImageSharp.fluid} />
            <Img fluid={data.mapImageDark.childImageSharp.fluid} />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Details

