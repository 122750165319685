import React from "react"

const Contact = () => {
  return (
    <div className="Contact__intro">
      <h1 className="fadein">Get in touch</h1>
      <p className="fadein has-big-font-size">You're always welcome to reach out, whether you want to discuss a project, have a chat or arrange a meeting. </p>
      <p className="fadein has-big-font-size">Fill in the form below, or <a href="mailto:henrik@hhb.dk?Subject=Hello%20Henrik">send me an email</a>.</p>
    </div>
  )
}

export default Contact

